import React from 'react';

import { SEO } from 'gatsby-theme-thepuzzlers-intl';
import { injectIntl } from 'gatsby-theme-thepuzzlers-intl';

// Local Components
import { Spacer } from 'components';
import { useBlogPageData, Header, BlogCards } from 'sections/blogPage';

// External Data
import { useKisBlogPostArticlesData, useKisBlogPageData } from 'boxenStopHooks';

// Utils
import getCorrectOrderOfBlogPosts from 'sections/blogPage/getCorrectOrderOfBlogPosts';

const Blog = () => {
  // Todo: remove Old unused Rest Api Blog Post Articles and articles later
  const { seo, readLink } = useBlogPageData();

  const { header: cmsHeader } = useKisBlogPageData();

  const blogPosts = useKisBlogPostArticlesData();

  return (
    <>
      <SEO {...seo} />
      <Spacer
        height={['4.8rem', '8.8rem', '8rem', '4.4rem', '10.4rem', '8rem']}
      />
      <Header data={cmsHeader} />
      <Spacer
        height={['9.2rem', '11.7rem', '13rem', '13rem', '18rem', '17.5rem']}
      />
      {/* static Data still required because at the moment cms gatsby image not working, so we fetch the image statically */}
      <BlogCards
        data={getCorrectOrderOfBlogPosts(blogPosts)}
        readLink={readLink}
      />
      <Spacer
        height={[
          '13.6rem',
          '6.9rem',
          '13.5rem',
          '14.1rem',
          '21.6rem',
          '22.7rem'
        ]}
      />
    </>
  );
};

export default injectIntl(Blog);
